import React from "react"

const Bus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="81" height="79" viewBox="0 0 81 79" fill="none">
    <path
      fill="currentColor"
      d="M3.797 19.75h1.265v-7.406C5.063 5.43 20.757 0 40.5 0s35.438 5.431 35.438 12.344v7.406h1.265C79.3 19.75 81 21.407 81 23.453v12.344c0 2.044-1.7 3.703-3.797 3.703h-1.266v24.688c0 2.726-2.267 4.937-5.062 4.937v4.938c0 2.726-2.267 4.937-5.063 4.937H60.75c-2.795 0-5.063-2.211-5.063-4.938v-4.937H25.314v4.938c0 2.726-2.268 4.937-5.063 4.937h-5.063c-2.795 0-5.062-2.211-5.062-4.938v-4.937H9.113c-2.532 0-4.05-1.975-4.05-3.95V39.5H3.796C1.7 39.5 0 37.841 0 35.797V23.453c0-2.046 1.7-3.703 3.797-3.703zM63.28 61.719c2.796 0 5.063-2.211 5.063-4.938 0-2.726-2.267-4.937-5.063-4.937-2.795 0-5.062 2.21-5.062 4.937 0 2.727 2.267 4.938 5.062 4.938zm-2.53-17.282c2.795 0 5.063-2.21 5.063-4.937V19.75c0-2.726-2.268-4.938-5.063-4.938h-40.5c-2.795 0-5.063 2.212-5.063 4.938V39.5c0 2.726 2.268 4.938 5.063 4.938h40.5zM17.719 61.72c2.795 0 5.062-2.211 5.062-4.938 0-2.726-2.267-4.937-5.062-4.937-2.796 0-5.063 2.21-5.063 4.937 0 2.727 2.267 4.938 5.063 4.938z"
    ></path>
  </svg>
)

export default Bus
