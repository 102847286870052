/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout, { Container } from "components/layout"
import Bus from "components/svgs/bus"
import Hero from "components/hero"
import ImageRow from "components/image-row"
import ImageText from "components/image-text"
import List from "components/list"
import { TileInfo, TileLink, TileWrapper } from "components/tiles"
import Title from "components/title"

export const query = graphql`
  query TransportationPageQuery {
    heroImage: file(relativePath: { eq: "hero/transportation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wasteImage: file(relativePath: { eq: "hero/waste.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bikeMonth: file(relativePath: { eq: "transportation/bike-month.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bikeParking: file(relativePath: { eq: "transportation/bike-parking.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hsrBus: file(relativePath: { eq: "transportation/hsr-bus.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    security: file(relativePath: { eq: "transportation/security.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const TransportationPage = ({ data }) => (
  <Layout
    pageTitle="Transportation"
    description="Mohawk partners with Smart Commute Hamilton to provide Transportation Demand Management programs that reduce single-occupant vehicle travel to campus."
  >
    <Title icon={<Bus />}>Transportation</Title>
    <Hero fluid={data.heroImage.childImageSharp.fluid} />

    <Container>
      <div sx={{ maxWidth: ["100%", "70%"] }}>
        <Styled.p>
          Transportation, particularly staff and student commuting, accounts for
          a significant proportion of Mohawk's carbon footprint.
        </Styled.p>
        <Styled.p>
          Mohawk partners with Smart Commute Hamilton to provide Transportation
          Demand Management (TDM) programs that reduce single-occupant vehicle
          travel to campus.
        </Styled.p>
        <Styled.p>
          The college actively promotes carpooling, bussing, cycling and
          walking. The Mohawk Students' Association (MSA) provides a universal
          transit pass to full-time students.
        </Styled.p>
      </div>
      <ImageRow>
        <Img fluid={data.bikeMonth.childImageSharp.fluid} />
        <Img fluid={data.bikeParking.childImageSharp.fluid} />
        <Img className="wide" fluid={data.hsrBus.childImageSharp.fluid} />
      </ImageRow>
    </Container>

    <div sx={{ bg: "muted" }}>
      <Container>
        <Styled.h2>Key Initiatives</Styled.h2>
        <List icon={<Bus />}>
          <Fragment>
            Continuation of the Universal Bus Pass for full-time students
          </Fragment>
          <Fragment>Expansion of the Bike Loan Program to 13 bikes</Fragment>
          <Fragment>Parking pass sharing for carpoolers</Fragment>
          <Fragment>
            Addition of 16 Electric Vehicle (EV) charging stations at Fennell
            and Stoney Creek campuses
          </Fragment>
          <Fragment>Mohawk College Transit Terminal</Fragment>
          <Fragment>Free, member-based secure bike parking facility</Fragment>
          <Fragment>Green Fleet Strategy for fleet vehicles</Fragment>
        </List>
      </Container>
    </div>

    <Container wide={true} spaced={false}>
      <TileWrapper>
        <TileInfo bg="darkPrimary" color="background">
          <Styled.p>
            <Styled.h1 as="span">65%</Styled.h1>
            <br />
            of trips by sustainable modes of transportation
          </Styled.p>
        </TileInfo>
        <TileInfo bg="primary" color="background">
          <Styled.p>
            <Styled.h1 as="span">200+</Styled.h1>
            <br />
            annual participants in campus cycling programs
          </Styled.p>
        </TileInfo>
        <TileInfo bg="accentTwo" color="dark">
          <Styled.p>
            <Styled.h1 as="span">24%</Styled.h1>
            <br />
            of fleet vehicles electric or hybrid
          </Styled.p>
        </TileInfo>
        <TileInfo bg="secondary" color="dark">
          <Styled.p>
            <Styled.h1 as="span">187</Styled.h1>
            <br />
            unique users of EV charging stations in 2019
          </Styled.p>
        </TileInfo>
      </TileWrapper>

      <ImageText image={data.security.childImageSharp.fluid}>
        <Styled.h2>Green Fleet Strategy</Styled.h2>
        <Styled.p>
          In 2019, Mohawk College implemented a five-year Green Fleet Strategy
          to reduce greenhouse gas emissions and operating costs associated with
          fleet vehicles.
        </Styled.p>
        <Styled.p>
          By 2024, Mohawk aims to improve fleet fuel efficiency by 20%, reduce
          fleet associated greenhouse gas emissions by 20%, ensure 75% of fleet
          vehicles targeted for replacement will be a hybrid or electric
          vehicle, reduce annual fleet kilometres driven by 10%, and reduce
          number of total fleet vehicles by 10%.
        </Styled.p>
      </ImageText>
    </Container>

    <Container>
      <Styled.h2>Continue Reading</Styled.h2>
      <TileLink to="/waste" image={data.wasteImage.childImageSharp.fluid}>
        Waste
      </TileLink>
    </Container>
  </Layout>
)

export default TransportationPage
